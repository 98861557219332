<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <users />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Users from "../components/Users.vue";
export default {
  components: { Users },
};
</script>
