<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <v-card-title>
      Users of the System
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      class="elevation-1"
    ></v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "userID" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Job Role", value: "jobRole" },
        { text: "Access Level", value: "accessLevel" },
      ],
      users: [],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      axios.get("http://localhost:8091/api/v1/users").then((response) => {
        this.users = response.data;
        console.log(this.users);
      });
    },
  },
};
</script>
